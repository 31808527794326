import React from 'react';
import styles from './SixthScreen.module.css';
import FAQItem from '../components/questions/Questions';
import Button from '../components/Button/Button';

const SixthScreen = () => {
  return <>

      <div className={styles.title}>אתם שואלים, אני עונה</div>

        <FAQItem 
          question="מה זה דף נחיתה?" 
          answer="דף נחיתה זה אתר אינטרנטי לכל דבר, כשמו כן הוא - דף. הדף הזה זמין 24/7 לכל מתעניין שמחפש לשמוע עוד על השירות שלך והדף הזה עובד להיות החבר הכי טוב שלך!"
        />
        <FAQItem 
          question="איך דף נחיתה חוסך לי זמן?" 
          answer="אתם לא זמינים כרגע? המתעניינים יכולים לקבל את המידע שהם רוצים לקבל בדף הנחיתה! שיווקתם פוסט בממומן? אתם יכולים להניע את המתעניינים ישירות לדף נחיתה ולהעביר אותם את התהליך שאתם רוצים שהם יעברו! בנוסף, דף נחיתה מסנן לכם אנשים פחות רציניים שמעוניינים לשמוע רק מחיר ואתם תעלו לשיחה רק עם הרציניים יותר! בקיצור.. הוא חוסך המון זמן "
        />
        <FAQItem 
          question="איך אתה יודע מה לכתוב בדף הנחיתה?" 
          answer="לאורך התהליך איתי אני מוציא ממך את המידע המקצועי,את המסרים שאתם רוצים להעביר,את נקודת המבט שלכם על קהל היעד ואת החוויות שלכם ממנו בשיחות ומשלב אותם בדף"
        />
        <FAQItem 
          question="האם עסק בתחילת דרכו צריך דף נחיתה?" 
          answer="בהחלט! דף נחיתה הוא השקעה חכמה שמחזירה את עצמה במהירות. הוא עוזר לבנות אמון, מייצר לידים איכותיים יותר ומאפשר לך להתחרות בשוק באופן מקצועי מהיום הראשון"
        />
        <FAQItem 
          question="איך אתה ממליץ להשתמש בדף הנחיתה?" 
          answer="מומלץ לשלב את הדף בכל נקודות המגע עם לקוחות - בביו באינסטגרם, בהודעות אוטומטיות בווצאפ, בקמפיינים ממומנים ובכל תקשורת עם לקוחות פוטנציאליים. זה מאפשר להם להכיר אותך ואת העסק עוד לפני השיחה הראשונה"
        />
        <FAQItem 
          question="לאיזה תחומים אתה בונה דפי נחיתה ואתרי תדמית?" 
          answer="אני בונה דפי נחיתה ואתרי תדמית לכל תחום עסקי. התהליך איתי מאפשר לי להתאים את הפתרון המושלם לכל סוג של עסק, תוך התמקדות במטרות הספציפיות שלך"
        />
        <FAQItem 
          question="מה ההבדל בין דף נחיתה לאתר תדמית?" 
          answer="דף נחיתה הוא אתר של עמוד אחד ואתר תדמיתי מורכז ממספר עמודים - רוב העסקים הקטנים יצטרכו דף, אלא אם יש להם כמות רחבה של שירותים שדורשת התייחסות רחבה לכל שירות"
        />

        <FAQItem 
          question="עד היום הסתדרתי בלי דף נחיתה, למה שאצטרך אחד?" 
          answer="השאלה היא לא אם הסתדרת, אלא כמה הזדמנויות עסקיות פספסת בדרך. דף נחיתה מקצועי יכול להגדיל משמעותית את ההמרות שלך, לחסוך עבורך זמן ולשפר את התדמית העסקית שלך בשוק התחרותי של היום"
        />
        <FAQItem 
          question="אני עובד במשרה נוספת - האם אני עדיין צריך תדמית דיגיטלית?" 
          answer="דווקא כשאתה עובד במשרה נוספת, תדמית דיגיטלית חזקה היא קריטית. היא עובדת בשבילך 24/7, מספקת מידע ללקוחות פוטנציאליים גם כשאתה לא זמין, ומייצרת הזדמנויות עסקיות באופן אוטומטי"
        />
 <Button text="דניאל, בוא נדבר!"/>
</>
};

export default SixthScreen;